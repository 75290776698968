import { useContext, useState, useEffect } from "react";
import styled, { css } from "styled-components";
import Styles from "../base/styles";
import SystemUtil from "../utils/systemUtil";
import { GlobalContext } from "./manage";

type XY = {
    x: number;
    y: number;
};

type BlockProps = {
    curIdx: XY;
    baseIdx: XY;
    pos: XY;
    poly: XY[];
};

type PictureProps = {
    name: string;
    src: string;
};

const Test = () => {

    const { store, setStore } = useContext(GlobalContext);

    const pictureList: PictureProps[] = [
        { name: 'SPARKLE', src: 'https://y.gtimg.cn/music/photo_new/T002R300x300M000002q24Gd0IecUp_2.jpg?max_age=2592000' },
        { name: 'Doing all right', src: 'https://livedoor.blogimg.jp/garnetcrow10th/imgs/8/6/86c484a7.jpg' },
        { name: 'parallel universe', src: 'https://images-na.ssl-images-amazon.com/images/I/91JBfLRH-lL._AC_SL1500_.jpg' },
        { name: 'STAY', src: 'https://blogimg.goo.ne.jp/user_image/69/c0/adffa73a204e6ffbd1ca0f89994cfb17.jpg' },
        { name: '夢みたあとで', src: 'https://s101.lzjoy.com/res/statics/fileupload/202004/9059425e8778daba6b4.jpg' },
        { name: 'Timeless Sleep', src: 'https://th.bing.com/th/id/OIP.oOaCUoxnwWDWemu_tzHQgQHaHa?pid=ImgDet&rs=1' },
        { name: '花は咲いてただ揺れて', src: 'https://高画質ジャケット画像.com/blog/wp-content/uploads/2019/03/GZCA-7149.jpg' },
        { name: '君を飾る花を咲かそう', src: 'https://img.hmv.co.jp/image/jacket/400/18/6/4/864.jpg' },
        { name: '夏の幻', src: 'https://i.scdn.co/image/ab67616d0000b27381a1bd33127bd5e8fd55f47f' },
    ];
    
    const [divCnt, setDivCnt] = useState(4);
    const [pictureIndex, setPictureIndex] = useState(0);

    const src = pictureList[pictureIndex].src;

    const rate = 100 / divCnt;

    const createTable = () => {
        const blocks: BlockProps[] = [];
        for (let i = 0; i < divCnt; i++) {
            for (let j = 0; j < divCnt; j++) {
                const pos: XY = { x: (100 / divCnt) * i, y: (100 / divCnt) * j };
                const poly: { x: number, y: number }[] = [
                    { x: 0 + pos.x, y: 0 + pos.y },
                    { x: rate + pos.x, y: 0 + pos.y },
                    { x: rate + pos.x, y: rate + pos.y },
                    { x: 0 + pos.x, y: rate + pos.y },
                ];
                const baseIdx = { x: i, y: j };
                const curIdx = { ...baseIdx };
                blocks.push({ baseIdx, curIdx, pos, poly });
            }
        }
        const randomSlide = () => {
            let blankPos: XY = { x: -1, y: -1 };
            blocks.forEach((block) => {
                if (block.baseIdx.x === divCnt - 1 && block.baseIdx.y === divCnt - 1) blankPos = block.curIdx;
            });
            const targetList: XY[] = [];
            blocks.forEach(block => {
                if (block.curIdx.x === blankPos.x || block.curIdx.y === blankPos.y) targetList.push(block.curIdx);
            });
            const cur = targetList[Math.floor(Math.random() * targetList.length)];
            const swapCache = { ...cur };
            if (cur.x === blankPos.x) {
                blocks.forEach(block => {
                    if (block.curIdx.x === blankPos.x) {
                        if (cur.y < blankPos.y) {
                            if (block.curIdx.y < blankPos.y && block.curIdx.y >= cur.y) block.curIdx.y++;
                        } else {
                            if (block.curIdx.y > blankPos.y && block.curIdx.y <= cur.y) block.curIdx.y--;
                        }
                    }
                });
            } else {
                blocks.forEach(block => {
                    if (block.curIdx.y === blankPos.y) {
                        if (cur.x < blankPos.x) {
                            if (block.curIdx.x < blankPos.x && block.curIdx.x >= cur.x) block.curIdx.x++;
                        } else {
                            if (block.curIdx.x > blankPos.x && block.curIdx.x <= cur.x) block.curIdx.x--;
                        }
                    }
                });
            }
            blankPos.x = swapCache.x;
            blankPos.y = swapCache.y;
        }
        for (let i = 0; i < 1000; i++) {
            randomSlide();
        }
        return blocks;
    }
    const [blocks, setBlocks] = useState<BlockProps[]>(createTable());

    useEffect(() => {
        setBlocks(createTable());
    }, [divCnt]);

    const getBlankPos = (): XY => {
        let ret: XY = { x: -1, y: -1 };
        blocks.forEach((block) => {
            if (block.baseIdx.x === divCnt - 1 && block.baseIdx.y === divCnt - 1) ret = block.curIdx;
        });
        return ret;
    };


    const blankPos = getBlankPos();
    const isClear = () => {
        return undefined == blocks.find(b => !(b.baseIdx.x === b.curIdx.x && b.baseIdx.y === b.curIdx.y));
    }
    const clear = isClear();
    const imgJsxList: JSX.Element[] = blocks.map((block, i) => {
        const poly = block.poly;
        const polyDef = `polygon(${poly[0].x}% ${poly[0].y}%, ${poly[1].x}% ${poly[1].y}%, ${poly[2].x}% ${poly[2].y}%, ${poly[3].x}% ${poly[3].y}%)`;
        const xRate = (block.curIdx.x - block.baseIdx.x) * rate;
        const yRate = (block.curIdx.y - block.baseIdx.y) * rate;
        const isBlank = block.baseIdx.x === divCnt - 1 && block.baseIdx.y === divCnt - 1;
        const isBase = block.baseIdx.x === block.curIdx.x && block.baseIdx.y === block.curIdx.y;
        return <_TrimImg key={i} src={src} polygon={polyDef} x={xRate} y={yRate} isBase={isBase} isBlank={isBlank && !isClear()} />;
    });

    const blockJsxList: JSX.Element[] = blocks.map((block, i) => {
        const xRate = block.curIdx.x * rate;
        const yRate = block.curIdx.y * rate;
        const isBlank = block.baseIdx.x === divCnt - 1 && block.baseIdx.y === divCnt - 1;
        const cur = block.curIdx;
        const isTarget = block.curIdx.x === blankPos.x || block.curIdx.y === blankPos.y;
        const slide = () => {
            if (!isTarget || clear) return;
            const swapCache = { ...cur };
            if (cur.x === blankPos.x) {
                blocks.forEach(block => {
                    if (block.curIdx.x === blankPos.x) {
                        if (cur.y < blankPos.y) {
                            if (block.curIdx.y < blankPos.y && block.curIdx.y >= cur.y) block.curIdx.y++;
                        } else {
                            if (block.curIdx.y > blankPos.y && block.curIdx.y <= cur.y) block.curIdx.y--;
                        }
                    }
                });
            } else {
                blocks.forEach(block => {
                    if (block.curIdx.y === blankPos.y) {
                        if (cur.x < blankPos.x) {
                            if (block.curIdx.x < blankPos.x && block.curIdx.x >= cur.x) block.curIdx.x++;
                        } else {
                            if (block.curIdx.x > blankPos.x && block.curIdx.x <= cur.x) block.curIdx.x--;
                        }
                    }
                });
            }
            blankPos.x = swapCache.x;
            blankPos.y = swapCache.y;
            setBlocks(blocks.slice());
        }
        return <_Block key={i} rate={rate} x={xRate} y={yRate} isClear={clear} isTarget={isTarget} isBlank={isBlank && !clear} onClick={slide} />;
    });

    return (
        <_Wrap>
            <InputComboboxForm
                titleLabel="写真" value={pictureIndex.toString()} setValue={(value: string) => {
                    setPictureIndex(Number(value));
                }}
                list={pictureList.map((item, i) => {
                    return { value: i.toString(), labelText: item.name }
                })}
            />
            <InputComboboxForm
                titleLabel="分割数" value={divCnt.toString()} setValue={(value: string) => {
                    setDivCnt(Number(value));
                }}
                list={[3, 4, 5, 6, 7, 8, 9].map((item) => {
                    return { value: item.toString(), labelText: `${item}×${item}` }
                })}
            />
            <_Frame><_Img src={src} /></_Frame>
            <_Frame>{imgJsxList}{blockJsxList}</_Frame>
        </_Wrap>
    );
}

export default Test;

export const InputComboboxForm = (props: {
    titleLabel: string;
    value: string;
    setValue: (value: string) => void;
    list: { value: string, labelText: string }[];
}) => {
    return (
        <_Record isEnable={true}>
            <_ItemnLabel>{props.titleLabel}</_ItemnLabel>
            <_ItemnCombobox value={props.value} onChange={(e) => {
                props.setValue(e.target.value);
            }} >{props.list.map((item, i) => {
                return <option key={i} value={item.value}>{item.labelText}</option>
            })}</_ItemnCombobox>
        </_Record>
    );
}

const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #c0c0c0;
`;

const _Frame = styled.div`
    display: inline-block;
    position: relative;
    margin: 20px 0 0 70px;
    width: ${SystemUtil.CONTENTS_WIDTH - 140}px;
    height: ${SystemUtil.CONTENTS_WIDTH - 140}px;
    background-color: #d9dae4;
`;

const _Img = styled.img`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
`;

const _Block = styled.div<{
    rate: number;
    x: number;
    y: number;
    isBlank: boolean;
    isTarget: boolean;
    isClear: boolean;
}>`
    ${props => !props.isBlank ? '' : css`
        display: none;
    `}
    position: absolute;
    width: ${props => props.rate}%;
    height: ${props => props.rate}%;
    left: ${props => props.x}%;
    top: ${props => props.y}%;
    ${props => !props.isTarget ? '' : css`
        /* background-color: #f8000050; */
    `}
    box-sizing: border-box;
    ${props => props.isClear ? '' : css`
        border: solid 1px #ffffff;
    `}
    ${props => props.isBlank ? '' : css`
        transition: left 0.2s, top 0.2s, background-color 0.2s;
    `}
    box-sizing: border-box;
`;

const _TrimImg = styled.img<{
    polygon: string;
    x: number;
    y: number;
    isBase: boolean;
    isBlank: boolean;
}>`
    ${props => !props.isBlank ? '' : css`
        opacity: 0;
    `}
    position: absolute;
    width: 100%;
    height: 100%;
    left: ${props => props.x}%;
    top: ${props => props.y}%;
    clip-path: ${props => props.polygon};
    transition: left 0.2s, top 0.2s, opacity 1s, filter 0.3s;
    ${props => props.isBase ? '' : css`
        filter: grayscale(100%);
    `}
`;


const _Record = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    width: 100%;
    height: 40px;
    /* background-color: #b3b4c4; */
    ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE}
`;

const _ItemnLabel = styled.div`
    display: inline-block;
    height: calc(100% - 4px);
    width: 140px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: #4e4e4e;
    border: solid 1px #727272;
    box-sizing: border-box;
    background: linear-gradient(to bottom, #a7a7a7, #c9c9c9, #a7a7a7);
    border-radius: 4px;
    text-align: center;
    vertical-align: top;
    margin-left: 4px;
    margin-top: 2px;
`;

const _ItemnInput = styled.input<{
    readOnly?: boolean;
}>`
    display: inline-block;
    height: calc(100% - 4px);
    width: calc(100% - 150px);
    margin-top: 2px;
    margin-left: 4px;
    font-size: 24px;
    line-height: 32px;
    /* font-weight: 600; */
    color: #1f1f1f;
    border: solid 1px #000;
    padding: 0 5px;
    box-sizing: border-box;
    background-color: #dbdbdb;
    text-align: left;
    vertical-align: top;
    user-select: text;
    ${props => !props.readOnly ? '' : css`
        color: #020075;
        border: none;
        outline: none;
        background-color: transparent;
    `}
`;

const _ItemnCombobox = styled.select<{
}>`
    display: inline-block;
    height: calc(100% - 4px);
    width: calc(100% - 150px);
    margin-top: 2px;
    margin-left: 4px;
    font-size: 24px;
    line-height: 32px;
    /* font-weight: 600; */
    color: #1f1f1f;
    border: solid 1px #000;
    padding: 0 5px;
    box-sizing: border-box;
    background-color: #dbdbdb;
    text-align: left;
    vertical-align: top;
    user-select: text;
`;