


namespace SystemUtil {

  export const CONTENTS_WIDTH = 576;
  export const CONTENTS_HEIGHT = 1024;
  export const USER_INFO = 30;

  export const FIELD_MARGIN = 10;
  export const MAX_CELL_WIDTH = 100;

  export function isSmartPhone() {
    // UserAgentからのスマホ判定
    if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
      return true;
    } else {
      return false;
    }
  }
}

export default SystemUtil;