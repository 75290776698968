import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import Manage from "./contents/manage";
import SystemUtil from "./utils/systemUtil";

type ScreenBean = {
    adjLeft: number;
    adjTop: number;
    rate: number;
}

const GameRoot = () => {

    const [screen, setScreen] = useState<ScreenBean>({ adjLeft: 0, adjTop: 0, rate: 1 });
    const ref = useRef<null | HTMLDivElement>(null);

    const update = () => {
        if (ref.current != null) {
            // const [clientWidth, clientHeight] = [window.innerWidth, window.innerHeight];

            const rect = ref.current.getBoundingClientRect();
            const [clientWidth, clientHeight] = [rect.width, rect.height];

            const cw = SystemUtil.CONTENTS_WIDTH;
            const ch = SystemUtil.CONTENTS_HEIGHT;

            const isFitSide = clientHeight / ch > clientWidth / cw;

            const rate = !isFitSide ? clientHeight / ch : clientWidth / cw;
            const adjLeft = !isFitSide ? clientWidth / 2 - (cw * rate / 2) : 0;
            const adjTop = !isFitSide ? 0 : clientHeight / 2 - (ch * rate / 2);

            setScreen({ adjLeft, adjTop, rate });
        }
    }

    window.addEventListener('DOMContentLoaded', () => {
        if(!SystemUtil.isSmartPhone()) {
            window.addEventListener('resize', update);
        } else {
            window.addEventListener('orientationchange resize', update);
        }
    });

    // window.onorientationchange = () => {
    //   setOrientation(window.orientation);
    // }

    useEffect(() => update(), []);

    return (
        <_Wrap ref={ref}>
            <_Frame left={screen.adjLeft} top={screen.adjTop} rate={screen.rate}>
                <_Contents>
                    {/* <EditMode/> */}
                    <Manage />
                </_Contents>
            </_Frame>
        </_Wrap>
    );
}

export default GameRoot;

const _Wrap = styled.div`
  display: inline-block;
  position: relative;
  /* width: calc(100% - 10px);
  height: calc(100% - 10px);
  background-color: #9eadc9;
  margin: 5px 0 0 5px; */
  width: 100%;
  height: 100%;
`;

const _Frame = styled.div<{
    left: number;
    top: number;
    rate: number;
}>`
  display: inline-block;
  position: absolute;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  transform-origin: top left;
  /* width: 100%;
  height: 100%; */
  transform: scale(${props => props.rate});
`;

const _Contents = styled.div`
  display: inline-block;
  width: ${SystemUtil.CONTENTS_WIDTH}px;
  height: ${SystemUtil.CONTENTS_HEIGHT}px;
  background-color: #929292;
`;