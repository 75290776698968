import { createContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { FieldCondition, Store } from "../redux/store";
import SystemUtil from "../utils/systemUtil";
import Test from "./Test";

type GlobalContextType = {
    store: Store;
    setStore: Function;
}

export const GlobalContext = createContext({} as GlobalContextType);

const Manage = () => {
    const [store, setStore] = useState<Store>({
        scene: 'entrance',
        user: null,
        fieldCondition: {} as FieldCondition,
        fieldState: { table: [], invadeRate: 0 },
        historyBack: []
    });

    const getCurGameMode = () => {
        switch (store.scene) {
            case 'entrance': return <Test />;
        }
    };

    const user = store.user;

    return (
        <GlobalContext.Provider value={{ store, setStore }}>
            <_ContentsDiv>
                {getCurGameMode()}
            </_ContentsDiv>
        </GlobalContext.Provider>
    );
}

export default Manage;

const _ContentsDiv = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
`;